var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    {
      staticStyle: {
        width: "130%",
        "text-align": "center",
        "margin-left": "-10%",
      },
      attrs: { bordered: false },
    },
    [
      _c(
        "a-steps",
        { staticClass: "steps", attrs: { current: _vm.currentTab } },
        [
          _c("a-step", { attrs: { title: "手机验证" } }),
          _c("a-step", { attrs: { title: "密码" } }),
          _c("a-step", { attrs: { title: "完成" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.currentTab === 0
            ? _c("step2", { on: { nextStep: _vm.nextStep } })
            : _vm._e(),
          _vm.currentTab === 1
            ? _c("step3", {
                attrs: { userList: _vm.userList },
                on: { nextStep: _vm.nextStep, prevStep: _vm.prevStep },
              })
            : _vm._e(),
          _vm.currentTab === 2
            ? _c("step4", {
                attrs: { userList: _vm.userList },
                on: { prevStep: _vm.prevStep, finish: _vm.finish },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }